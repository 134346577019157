import { Image } from 'src/components/ui/Image'

const InfoCardGol = () => {
  return (
    <>
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-14.png"
        alt="proj-3"
        className="info-image"
        width={418}
        height={314}
        loading="lazy"
      />
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/icon-fundacao-bola.png"
        alt="icon bola"
        className="flex my-0 mx-auto mt-4"
        width={32}
        height={32}
        loading="lazy"
      />
      <h1 className="text-center font-semibold text-black">GOL DE LETRA</h1>
      <p className="description scrollbar text-center text-xl text-veryDarkShadeOfGray">
        A Gol de Letra desenvolve projetos que visam promover a educação
        integral de crianças, adolescentes, jovens e adultos por meio do
        esporte, cultura e formação para o trabalho.
      </p>
    </>
  )
}

export default InfoCardGol
