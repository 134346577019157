import Fundacao from 'src/components/Institucional/Fundacao'
import Seo from 'src/components/seo/Seo'

function Page() {
  return (
    <>
      <Seo title="Fundação | Decathlon" />
      <Fundacao />
    </>
  )
}

export default Page
