import { Image } from 'src/components/ui/Image'

const InfoCardTapera = () => {
  return (
    <>
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/bottomcarousel1.png"
        alt="proj-4"
        className="info-image"
        width={418}
        height={314}
        loading="lazy"
      />
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/icon-fundacao-prancha.png"
        alt="icon prancha"
        className="flex my-0 mx-auto mt-4"
        width={32}
        height={32}
        loading="lazy"
      />
      <h1 className="text-center font-semibold text-black">TAPERA</h1>
      <p className="description scrollbar text-center text-xl text-veryDarkShadeOfGray">
        O Tapera Stand Up Paddle é um projeto social esportivo que atende o
        Centro de Convivência e Fortalecimento de Vínculos de Crianças e
        Adolescentes da Tapera.
      </p>
    </>
  )
}

export default InfoCardTapera
