import { Image } from 'src/components/ui/Image'

const InfoCardMiratus = () => {
  return (
    <>
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-3.png"
        alt="proj-1"
        className="info-image"
        width={418}
        height={314}
        loading="lazy"
      />
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/icon-fundacao-badminton.png"
        alt="icon badmington"
        className="flex my-0 mx-auto mt-4"
        width={28}
        height={28}
        loading="lazy"
      />
      <h1 className="text-center font-semibold text-black">MIRATUS</h1>
      <p className="description scrollbar text-center text-xl text-veryDarkShadeOfGray">
        Uma entidade não governamental sem fins lucrativos que promove o
        desenvolvimento social por meio da educação e do esporte (focando no
        Badminton)
      </p>
    </>
  )
}

export default InfoCardMiratus
