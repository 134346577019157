import { Image } from 'src/components/ui/Image'

type FoundationCardProps = {
  imgSet: string[]
  altSet: string[]
  title: string
  paragraph: string
  classe: string
}

const FoundationCard = ({
  imgSet,
  altSet,
  title,
  paragraph,
  classe,
}: FoundationCardProps) => {
  return (
    <>
      <Image
        src={`https://decathlonproqa.vtexassets.com/arquivos/${imgSet[0]}`}
        alt={altSet[0]}
        className="info-image"
        width={418}
        height={314}
        loading="lazy"
      />
      <Image
        src={`https://decathlonproqa.vtexassets.com/arquivos/${imgSet[0]}`}
        alt={altSet[1]}
        className={`flex my-0 mx-auto mt-4 ${classe}`}
        height={28}
        width={28}
        loading="lazy"
      />
      <h1 className="text-center font-semibold text-black">{title}</h1>
      <p className="description scrollbar text-xl text-center text-veryDarkShadeOfGray">
        {paragraph}
      </p>
    </>
  )
}

export default FoundationCard
