import { Image } from 'src/components/ui/Image'

const InfoCardFaz = () => {
  return (
    <>
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/bottomcarousel2.png"
        alt="proj-5"
        className="info-image"
        width={418}
        height={314}
        loading="lazy"
      />
      <Image
        src="https://decathlonproqa.vtexassets.com/arquivos/icon-fundacao-faz.png"
        alt="icon faz"
        className="flex my-0 mx-auto mt-4"
        width={32}
        height={32}
        loading="lazy"
      />
      <h1 className="text-center font-semibold text-black">FAZ</h1>
      <p className="description scrollbar text-center text-xl text-veryDarkShadeOfGray">
        A Associação FAZ Alessandro Zarzur tem como missão formar jovens e
        adolescentes através do esporte e da educação, oferecendo a eles
        oportunidades únicas.
      </p>
    </>
  )
}

export default InfoCardFaz
