import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import { Navigation } from 'swiper'

import InfoCardMiratus from './InfoCardMiratus'
import InfoCardGol from './InfoCardGol'
import InfoCardTapera from './InfoCardTapera'
import InfoCardFaz from './InfoCardFaz'
import FoundationCard from './FoundationCard'

import './styles.scss'

const FoundationTopCarousel = () => {
  return (
    <div className="foundation-carousel-bottom my-0 mx-auto py-0 px-3">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop
        navigation
        modules={[Navigation]}
        className="foundation mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        <SwiperSlide className="infoCard">
          <InfoCardMiratus />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <InfoCardGol />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <InfoCardTapera />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <InfoCardFaz />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['carrosel-projeto-4.png', 'icon-fundacao-badminton.png']}
            altSet={['proj-6', 'icon badmington']}
            title="CASA DO ZÉZINHO"
            paragraph="Espaço de oportunidades de desenvolvimento para crianças e jovens
            que vivem em situação de alta vulnerabilidade social,
            disponibilizando diversas atividades extracurriculares."
            classe="h-7"
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['carrosel-projeto-4.png', 'icon-fundacao-badminton.png']}
            altSet={['proj-7', 'icon badmington']}
            title="QUERUBINS"
            paragraph="Voltada, há 27 anos, para o desenvolvimento de crianças e jovens
            através da arte. Ao pé da Serra do Curral, um espaço de 10.000 m²,
            onde são oferecidas oficinas de Danças Urbanas, Dança Afro, Dança
            Contemporânea, Circo, Artes Visuais, Percussão, Construção de
            Instrumentos, Vídeo e Cinema, além das oficinas de apoio escolar,
            cultivo de horta orgânica e jardinagem, esportes, informática, curso
            de culinária para crianças e capacitação profissional para jovens e
            adultos."
            classe=""
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['carrosel-projeto-4.png', 'icon-fundacao-badminton.png']}
            altSet={['proj-8', 'icon badmington']}
            title="RECANTO COLÔNIA VENEZA"
            paragraph="Situada e Peruíbe e mantida pelo Centro Ecumênico de Publicações e
            Estudos Frei Tito de Alencar Lima, ajuda crianças e jovens de
            escolas públicas, ou moradores da comunidade local, como alternativa
            de tirá-los das ruas e possibilitar sua inserção em atividades
            produtivas e saudáveis no horário adverso ao da escola e ou
            trabalho. As atividades oferecidas são: computação, reforço escolar
            e esportes como, dança, capoeira e outros, como também artesanato e
            música."
            classe=""
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={[
              'sociedade-crescerr.png',
              'icon-fundacao-sociedade-crescer.png',
            ]}
            altSet={['proj-9', 'icon sociedade crescer']}
            title="SOCIEDADE CRESCER"
            paragraph="A sociedade Crescer é uma associação que presta atendimento diário a
            crianças e jovens, oferecendo refeições e atividades pedagógicas,
            esportivas e cívicas."
            classe=""
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={[
              'carrosel-projeto-11.png',
              'icon-fundacao-arca-do-saber.png',
            ]}
            altSet={['proj-10', 'icon arca do saber']}
            title="ARCA DO SABER"
            paragraph="Há 20 anos a Arca acompanha o desenvolvimento dos moradores da
            favela desde os 6 anos de idade até a fase adulta, oferecendo
            formação e qualificação."
            classe=""
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['carrosel-projeto-12.png', 'icon-fundacao-corujinha.png']}
            altSet={['proj-11', 'icon corujinha']}
            title="CORUJINHA"
            paragraph="O Projeto Corujinha tem como objetivo oferecer o golfe ao maior
            número possível de crianças com o propósito de desenvolver a
            educação, a inclusão social e novas oportunidades de futuro pelo
            acesso a este tão respeitado esporte."
            classe=""
          />
        </SwiperSlide>
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['carrosel-projeto-8.png', 'icon-fundacao-eds.png']}
            altSet={['proj-12', 'icon eds']}
            title="EDS"
            paragraph="A Associação Expedicionários da Saúde (EDS) é uma organização
            brasileira sem fins lucrativos criada em 2003 por um grupo de
            médicos voluntários. Tem como objetivo principal levar atendimento
            médico, principalmente cirúrgico, às populações geograficamente
            isoladas da região da Amazônia Legal Brasileira."
            classe=""
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default FoundationTopCarousel
